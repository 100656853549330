import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ReactToPrint from 'react-to-print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import IconButton from '@mui/material/IconButton';

export default class Etats extends Component {

    constructor(props) {
        super(props)
        this.state = {
            payments: [],
            ClasseYears: [],
            studentYears: [],
            AccountYears: [],
            tuteurs: [],

            account_year_id: null,
            student_id: null,
            classe_id: null,

            severity: "",
            isAlert:false,
            loading: true,         
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | États"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/stats', config)
        .then(response => {
            this.setState({ 
                payments: response.data.data,
                ClasseYears: response.data.ClasseYears,
                studentYears: response.data.studentYears,
                AccountYears: response.data.AccountYears,
                tuteurs: response.data.tuteurs,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {

        const {
            payments,
            ClasseYears,
            studentYears,
            AccountYears,
            tuteurs,

            classe_id,
            student_id,
            account_year_id,           

            loading,
            severity,
            message,
            isAlert
        } = this.state

        let payment = payments
        let ClasseYear = ClasseYears
        let studentYear = studentYears
        let AccountYear = AccountYears
        if(this.props.year_id !== null){
            payment = payment.filter(c => c["year_id"] === this.props.year_id)
            ClasseYear = ClasseYear.filter(c => c["year_id"] === this.props.year_id)
            studentYear = studentYear.filter(c => c["year_id"] === this.props.year_id)
            AccountYear = AccountYear.filter(c => c["year_id"] === this.props.year_id)
        }
        if(classe_id !== null){
            payment = payment.filter(c => c["classe_id"] === classe_id)
            studentYear = studentYear.filter(c => c["classe_id"] === classe_id)
            AccountYear = AccountYear.filter(c => c["classe_id"] === classe_id)
        }
        if(student_id !== null){
            payment = payment.filter(c => c["student_id"] === student_id)
        }
        if(account_year_id !== null){
            payment = payment.filter(c => c["account_year_id"] === account_year_id)
        }
        if(this.props.accesses.type == 'student'){
            const user_id = JSON.parse(localStorage.getItem("USER")).id
            payment = payment.filter(c => c["user_id"] === user_id)
        }
        if(this.props.accesses.type == 'parent'){
            const user_id = JSON.parse(localStorage.getItem("USER")).id
            const tuteur_id  = tuteurs.filter(c => c["user_id"] === user_id)[0]?.id
            payment = payment.filter(c => c["student"].tuteur_id === tuteur_id)
        }

        const displayAccount = (account_year_id) => {
            let resultat = ""
            if(AccountYears.filter(c => c["id"] === account_year_id)[0] != undefined){
                resultat = AccountYears.filter(c => c["id"] === account_year_id)[0].account.name
            }
            return resultat
        }      
        
        const getSomm = () => {
            let resultat = 0
            payment.map((answer) => {
                resultat = resultat + answer.amont
            })
            return resultat
        }

        return(
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end", alignItems: 'center'}}>
                    <TextField
                        select
                        label="Classe"
                        name="classe_id"
                        onChange={this.handleChange.bind(this)}
                        size="small"
                        style={{ width: 200, marginRight: 16 }}
                        value={classe_id || ''}
                    >
                        <MenuItem value={null}>Toutes les classes</MenuItem>

                        {ClasseYear.map((option) => (
                            <MenuItem key={option.id} value={option.classe_id}>
                                {option.classe.name} | {option.cycle.name} | {option.year.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Étudiant(e)"
                        name="student_id"
                        onChange={this.handleChange.bind(this)}
                        size="small"
                        style={{ width: 200, marginRight: 16 }}
                        value={student_id || ''}
                    >
                        <MenuItem value={null}>Tous les étudiants</MenuItem>

                        {studentYear.map((option) => (
                            <MenuItem key={option.id} value={option.student_id}>
                                {option.user.matricule} | {option.user.first_name} | {option.user.second_name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Types de frais"
                        name="account_year_id"
                        onChange={this.handleChange.bind(this)}
                        size="small"
                        style={{ width: 200, marginRight: 16  }}
                        value={account_year_id || ''}
                    >
                        <MenuItem value={null}>Tous les types de frais</MenuItem>

                        {AccountYear.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.account.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <ReactToPrint
                        trigger={() => <IconButton size="large"><LocalPrintshopIcon fontSize="inherit" /></IconButton>}
                        content={() => this.componentRef}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={0}  sx={{ minHeight:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <div className="row" style={{width: "100%"}} ref={el => (this.componentRef = el)}>
                            <div className="col-12 text-center" style={{marginTop:24}}><h5>Journal de caisse</h5></div>

                            <div className="col-12">
                                <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                    <thead>
                                        <tr>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Date de l'opération</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Matricule</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Nom complet</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Classe</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Année</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Type de frais</Typography></th>
                                            <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Montant Payé</Typography></th>                                                    
                                        </tr>
                                    </thead>

                                    <tbody>  
                                        {payment.map((answer) => (
                                            <tr>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{new Date(answer.created_at).toLocaleString("lookup")}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.user.matricule}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.user.first_name + ' ' + answer.user.second_name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.classe.name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.year.name}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{displayAccount(answer.account_year_id)}</Typography></td>
                                                <td><Typography color="text.secondary" sx={{fontSize:16 }}>{answer.amont.toLocaleString()}</Typography></td>
                                            </tr>
                                        ))} 

                                        <tr>
                                            <td colspan="6" style={{fontWeight: 600}}>Total</td>
                                            <td colspan="2" style={{fontWeight: 600}}>{getSomm().toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>   
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}
