import React, { Component } from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'

export default class Logs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logs: [],

            severity: "",
            isAlert:false,
            loading: true,       
        }
    }


    componentDidMount(){
        document.title = SchoolName() + " | Traces des activites"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/logs', config)
        .then(response => {
            this.setState({ 
                logs: response.data.data,
                loading: false,  
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    render() {

        const {
            logs,
            loading,
            severity,
            message,
            isAlert
        } = this.state

        const column = [
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1/2,
                renderCell: (cellValues) => {return  cellValues.row.user?.matricule || "Suprimer"}
            },
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2,
                renderCell: (cellValues) => {return  cellValues.row.user.first_name + ' ' + cellValues.row.user.second_name}
            },
            { 
                field: 'action', 
                headerName: 'Action',
                flex: 1/2,
                renderCell: (cellValues) => {
                    if(cellValues.row.log_type === "create"){
                        return  "Ajout"
                    }
                    else if(cellValues.row.log_type === "edit"){
                        return  "Modification"
                    }
                    else if(cellValues.row.log_type === "delete"){
                        return  "Suppression"
                    }
                    else if(cellValues.row.log_type === "Approval"){
                        return  "Approbation"
                    }
                    else if(cellValues.row.log_type === "EnableDesable"){
                        return  "Activer / Désactiver"
                    }
                    else if(cellValues.row.log_type === "lockout"){
                        return  "Déconnexion"
                    }
                    else if(cellValues.row.log_type === "login"){
                        return  "Authentification"
                    }
                    else if(cellValues.row.log_type === "ResetPassword"){
                        return  "Réinitialiser le mot de passe"
                    }
                    
                    else{
                        return cellValues.row.action
                    }
                }
            },
            { 
                field: 'data', 
                headerName: 'Donnée',
                flex: 1/2,
                renderCell: (cellValues) => { //return cellValues.row.table_name }
                    if(cellValues.row.table_name === ""){
                        return  "Se connecter"
                    }
                    else if(cellValues.row.table_name === "users"){
                        return  "Utilisateurs"
                    }
                    else if(cellValues.row.table_name === "rules"){
                        return  "Rôles et permissions"
                    }
                    else if(cellValues.row.table_name === "years"){
                        return  "Année academique"
                    }
                    else if(cellValues.row.table_name === "cycles"){
                        return  "Cycles scolaires"
                    }
                    else if(cellValues.row.table_name === "classes"){
                        return  "Classes scolaires"
                    }
                    else if(cellValues.row.table_name === "subjects"){
                        return  "Matières scolaires"
                    }
                    else if(cellValues.row.table_name === "students"){
                        return  "Étudiants"
                    }
                    else if(cellValues.row.table_name === "albums"){
                        return  "Albums scolaires"
                    }
                    else if(cellValues.row.table_name === "classe_years"){
                        return  "Classe | administration"
                    }
                    else if(cellValues.row.table_name === "cycle_years"){
                        return  "Cycle | administration"
                    }
                    else if(cellValues.row.table_name === "accounts"){
                        return  "Types de frais"
                    }
                    else if(cellValues.row.table_name === "notes"){
                        return  "Notes"
                    }
                    else if(cellValues.row.table_name === "subject_years"){
                        return  "Matière | administration"
                    }
                    else if(cellValues.row.table_name === "compositions"){
                        return  "Compositions"
                    }
                    else if(cellValues.row.table_name === "composition_years"){
                        return  "Compositions | administration"
                    }
                    else if(cellValues.row.table_name === "student_years"){
                        return  "Étudiants | administration"
                    }
                    else if(cellValues.row.table_name === "account_years"){
                        return  "Frais scolaire | Comptabilité"
                    }
                    else if(cellValues.row.table_name === "payments"){
                        return  "Paiement | Comptabilité"
                    }
                    else if(cellValues.row.table_name === "announcements"){
                        return  "Annonces"
                    }else if(cellValues.row.table_name === "tuteurs"){
                        return  "Parents"
                    }else if(cellValues.row.table_name === "media"){
                        return  "Galerie"
                    }else{ 
                        return cellValues.row.table_name
                    }
                }

            },
            { 
                field: 'detail', 
                headerName: 'Détails',
                flex: 1,
                renderCell: (cellValues) => {return  cellValues.row.data}
            },
            { 
                field: 'date', 
                headerName: 'Date',
                flex: 1/2,
                renderCell: (cellValues) => {return new Date(cellValues.row.log_date).toLocaleString()}
            }

        ]

        let DataLogs=logs
        if(this.props.search !== ''){
            let result = []
            DataLogs.map((elem, index) => {
                elem.search = elem.user.first_name + ' ' + elem.user.second_name + ' ' + elem.user.matricule
                result = result.concat(elem)
            })
            DataLogs = result.filter(elem => { return elem.search.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} >
                    <Card elevation={6}  sx={{height:620, backgroundColor:'#ffffff', borderRadius:2, mt:8}}>
                        <DataGrid
                            rows={DataLogs}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {labelRowsPerPage: 'Ligne par page'}
                            }}
                        />
                    </Card>
                </Grid>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}