import React, { Component } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList'; 
import Typography from '@mui/material/Typography';

export default class Rules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rules: [],
            accesses: [],
            userss: [],

            dashbord: {read: false },
            parents: {read: false, write: false, approve: false, remove: false}, 
            marks: {read: false, write: false},
            notes: {read: false},
            gradebooks: {read: false},
            gradebookYear: {read: false},
            propositionYear: {read: false},
            galerie: {read: false, write: false, remove: false},
            announcement: {read: false, write: false, approve: false},
            fees: {read: false, write: false, remove: false},
            paiements: {read: false, write: false, remove: false},
            etats: {read: false },
            cycleYear: {read: false, write: false, approve: false},
            classeYear: {read: false, write: false, approve: false},
            studentYear: {read: false, write: false, remove: false},
            compositionYear: {read: false, write: false, approve: false},
            subjectYear: {read: false, write: false, remove: false},
            users: {read: false, write: false, approve: false},
            rule: {read: false, write: false, approve: false},
            cycles: {read: false, write: false, approve: false},
            classes: {read: false, write: false, approve: false},
            students: {read: false, write: false, approve: false},
            compositions: {read: false, write: false, approve: false},
            subjects: {read: false, write: false, approve: false},
            albums: {read: false, write: false, approve: false},
            accounts: {read: false, write: false, approve: false},
            logs: {read: false }, 
            year: {read: false, write: false, approve: false},

            add: false,
            edit: false,
            open: false,
            id: null,
            name:'',

            severity: "",
            isAlert:false,
            loading: true,

            anchorEl: [],   
            
            valuetab: "1"
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Rôles et permissions"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/rules', config)
        .then(response => {
            this.setState({ 
                rules: response.data.data,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){ this.setState({isAlert:false}) }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        const accesses = [
            {
                "module": "dashbord",
                "read": this.state.dashbord.read,
            },
            {
                "module": "parents",
                "read": this.state.parents.read,
                "write": this.state.parents.write,
                "approve": this.state.parents.approve,
                "remove": this.state.parents.remove
            },
            {
                "module": "marks",
                "read": this.state.marks.read,
                "write": this.state.marks.write,
            },
            {
                "module": "notes",
                "read": this.state.notes.read,
            },
            {
                "module": "gradebooks",
                "read": this.state.gradebooks.read,
            },
            {
                "module": "gradebookYear",
                "read": this.state.gradebookYear.read,
            },
            {
                "module": "propositionYear",
                "read": this.state.propositionYear.read,
            },
            {
                "module": "galerie",
                "read": this.state.galerie.read,
                "write": this.state.galerie.write,
                "remove": this.state.galerie.remove
            },
            {
                "module": "announcement",
                "read": this.state.announcement.read,
                "write": this.state.announcement.write,
                "approve": this.state.announcement.approve
            },
            {
                "module": "fees",
                "read": this.state.fees.read,
                "write": this.state.fees.write,
                "remove": this.state.fees.remove
            },
            {
                "module": "paiements",
                "read": this.state.paiements.read,
                "write": this.state.paiements.write,
                "remove": this.state.paiements.remove
            },
            {
                "module": "etats",
                "read": this.state.etats.read
            },
            {
                "module": "cycleYear",
                "read": this.state.cycleYear.read,
                "write": this.state.cycleYear.write,
                "approve": this.state.cycleYear.approve
            },
            {
                "module": "classeYear",
                "read": this.state.classeYear.read,
                "write": this.state.classeYear.write,
                "approve": this.state.classeYear.approve
            },
            {
                "module": "studentYear",
                "read": this.state.studentYear.read,
                "write": this.state.studentYear.write,
                "remove": this.state.studentYear.remove
            },
            {
                "module": "compositionYear",
                "read": this.state.compositionYear.read,
                "write": this.state.compositionYear.write,
                "approve": this.state.compositionYear.approve
            },
            {
                "module": "subjectYear",
                "read": this.state.subjectYear.read,
                "write": this.state.subjectYear.write,
                "remove": this.state.subjectYear.remove
            },
            {
                "module": "users",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "rule",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "cycles",
                "read": this.state.cycles.read,
                "write": this.state.cycles.write,
                "approve": this.state.cycles.approve
            },
            {
                "module": "classes",
                "read": this.state.classes.read,
                "write": this.state.classes.write,
                "approve": this.state.classes.approve
            },
            {
                "module": "students",
                "read": this.state.students.read,
                "write": this.state.students.write,
                "approve": this.state.students.approve
            },
            {
                "module": "compositions",
                "read": this.state.compositions.read,
                "write": this.state.compositions.write,
                "approve": this.state.compositions.approve
            },
            {
                "module": "subjects",
                "read": this.state.subjects.read,
                "write": this.state.subjects.write,
                "approve": this.state.subjects.approve
            },
            {
                "module": "albums",
                "read": this.state.albums.read,
                "write": this.state.albums.write,
                "approve": this.state.albums.approve
            },
            {
                "module": "accounts",
                "read": this.state.accounts.read,
                "write": this.state.accounts.write,
                "approve": this.state.accounts.approve
            },
            {
                "module": "logs",
                "read": this.state.logs.read
            },
            {
                "module": "year",
                "read": this.state.year.read,
                "write": this.state.year.write,
                "approve": this.state.year.approve
            },
        ]
            
        const bodyParameters = {
            name: this.state.name,
            accesses: JSON.stringify(accesses)
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.post(MainUrl() + '/api/v1/rules',
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode ===201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    add: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()
            }                       
        }).catch(error => {
            this.setState({
                add: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true})   

        const accesses = [
            {
                "module": "dashbord",
                "read": this.state.dashbord.read,
            },
            {
                "module": "parents",
                "read": this.state.parents.read,
                "write": this.state.parents.write,
                "approve": this.state.parents.approve,
                "remove": this.state.parents.remove
            },
            {
                "module": "marks",
                "read": this.state.marks.read,
                "write": this.state.marks.write,
            },
            {
                "module": "notes",
                "read": this.state.notes.read,
            },
            {
                "module": "gradebooks",
                "read": this.state.gradebooks.read,
            },
            {
                "module": "gradebookYear",
                "read": this.state.gradebookYear.read,
            },
            {
                "module": "propositionYear",
                "read": this.state.propositionYear.read,
            },
            {
                "module": "galerie",
                "read": this.state.galerie.read,
                "write": this.state.galerie.write,
                "remove": this.state.galerie.remove
            },
            {
                "module": "announcement",
                "read": this.state.announcement.read,
                "write": this.state.announcement.write,
                "approve": this.state.announcement.approve
            },
            {
                "module": "fees",
                "read": this.state.fees.read,
                "write": this.state.fees.write,
                "remove": this.state.fees.remove
            },
            {
                "module": "paiements",
                "read": this.state.paiements.read,
                "write": this.state.paiements.write,
                "remove": this.state.paiements.remove
            },
            {
                "module": "etats",
                "read": this.state.etats.read
            },
            {
                "module": "cycleYear",
                "read": this.state.cycleYear.read,
                "write": this.state.cycleYear.write,
                "approve": this.state.cycleYear.approve
            },
            {
                "module": "classeYear",
                "read": this.state.classeYear.read,
                "write": this.state.classeYear.write,
                "approve": this.state.classeYear.approve
            },
            {
                "module": "studentYear",
                "read": this.state.studentYear.read,
                "write": this.state.studentYear.write,
                "remove": this.state.studentYear.remove
            },
            {
                "module": "compositionYear",
                "read": this.state.compositionYear.read,
                "write": this.state.compositionYear.write,
                "approve": this.state.compositionYear.approve
            },
            {
                "module": "subjectYear",
                "read": this.state.subjectYear.read,
                "write": this.state.subjectYear.write,
                "remove": this.state.subjectYear.remove
            },
            {
                "module": "users",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "rule",
                "read": this.state.users.read,
                "write": this.state.users.write,
                "approve": this.state.users.approve
            },
            {
                "module": "cycles",
                "read": this.state.cycles.read,
                "write": this.state.cycles.write,
                "approve": this.state.cycles.approve
            },
            {
                "module": "classes",
                "read": this.state.classes.read,
                "write": this.state.classes.write,
                "approve": this.state.classes.approve
            },
            {
                "module": "students",
                "read": this.state.students.read,
                "write": this.state.students.write,
                "approve": this.state.students.approve
            },
            {
                "module": "compositions",
                "read": this.state.compositions.read,
                "write": this.state.compositions.write,
                "approve": this.state.compositions.approve
            },
            {
                "module": "subjects",
                "read": this.state.subjects.read,
                "write": this.state.subjects.write,
                "approve": this.state.subjects.approve
            },
            {
                "module": "albums",
                "read": this.state.albums.read,
                "write": this.state.albums.write,
                "approve": this.state.albums.approve
            },
            {
                "module": "accounts",
                "read": this.state.accounts.read,
                "write": this.state.accounts.write,
                "approve": this.state.accounts.approve
            },
            {
                "module": "logs",
                "read": this.state.logs.read
            },
            {
                "module": "year",
                "read": this.state.year.read,
                "write": this.state.year.write,
                "approve": this.state.year.approve
            },
        ]
            
        const bodyParameters = {
            name: this.state.name,
            accesses: JSON.stringify(accesses)
        }

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.put(MainUrl() + '/api/v1/rule/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {
            if(response.data.statusCode ===201){
                this.setState({
                    loading: false,
                    severity: 'error',
                    message: response.data.message,
                    isAlert: true
                }) 
            } else{
                this.setState({
                    edit: false,
                    loading: false,
                    severity: 'success',
                    message: response.data.message,
                    isAlert: true
                }) 
                this.componentDidMount()
            }                       
        }).catch(error => {
            this.setState({
                edit: false,
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    show(id){
        this.setState({loading: true})
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/rule/' + id, config)
        .then(response => {
            this.setState({ 
                name: response.data.rule.name,
                accesses: JSON.parse(response.data.rule.accesses), 
                userss: response.data.userss,
                open: true,
                loading: false,
                anchorEl: [], 
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true,
                anchorEl: [], 
            }) 
        }); 

    }

    edit(id){
        this.setState({loading: true}) 
        const item = this.state.rules.filter(c => c["id"] === id)[0]
        const accesses = JSON.parse(item.accesses)
        this.setState({
            id: id,
            name: item.name,
            dashbord: {
                read: accesses[0].read 
            },
            parents: {
                read: accesses[1].read, 
                write: accesses[1].write, 
                approve: accesses[1].approve,
                remove: accesses[1].remove
            },
            marks: {
                read: accesses[2].read, 
                write: accesses[2].write, 
            },
            notes: {
                read: accesses[3].read
            },
            gradebooks: {
                read: accesses[4].read
            },
            gradebookYear: {
                read: accesses[5].read
            },
            propositionYear: {
                read: accesses[6].read
            },
            galerie: {
                read: accesses[7].read, 
                write: accesses[7].write,
                remove: accesses[7].remove
            },
            announcement: {
                read: accesses[8].read, 
                write: accesses[8].write, 
                approve: accesses[8].approve
            },
            fees: {
                read: accesses[9].read, 
                write: accesses[9].write, 
                remove: accesses[9].remove
            },
            paiements: {
                read: accesses[10].read, 
                write: accesses[10].write, 
                remove: accesses[10].remove
            },
            etats: {
                read: accesses[11].read
            },
            cycleYear: {
                read: accesses[12].read, 
                write: accesses[12].write, 
                approve: accesses[12].approve
            },
            classeYear: {
                read: accesses[13].read, 
                write: accesses[13].write, 
                approve: accesses[13].approve
            },
            studentYear: {
                read: accesses[14].read, 
                write: accesses[14].write, 
                remove: accesses[14].remove
            },
            compositionYear: {
                read: accesses[15].read, 
                write: accesses[15].write, 
                approve: accesses[15].approve
            },
            subjectYear: {
                read: accesses[16].read, 
                write: accesses[16].write, 
                remove: accesses[16].remove
            },
            users: {
                read: accesses[17].read, 
                write: accesses[17].write, 
                approve: accesses[17].approve
            },
            rule: {
                read: accesses[18].read, 
                write: accesses[18].write, 
                approve: accesses[18].approve
            },
            cycles: {
                read: accesses[19].read, 
                write: accesses[19].write, 
                approve: accesses[19].approve
            },
            classes: {
                read: accesses[20].read, 
                write: accesses[20].write, 
                approve: accesses[20].approve
            },
            students: {
                read: accesses[21].read, 
                write: accesses[21].write, 
                approve: accesses[21].approve
            },
            compositions: {
                read: accesses[22].read, 
                write: accesses[22].write, 
                approve: accesses[22].approve
            },
            subjects: {
                read: accesses[23].read, 
                write: accesses[23].write, 
                approve: accesses[23].approve
            },
            albums: {
                read: accesses[24].read, 
                write: accesses[24].write, 
                approve: accesses[24].approve
            },
            accounts: {
                read: accesses[25].read, 
                write: accesses[25].write, 
                approve: accesses[25].approve
            },
            logs: {
                read: accesses[26].read
            },
            year: {
                read: accesses[27].read, 
                write: accesses[27].write, 
                approve: accesses[27].approve
            },
            edit: true,
            anchorEl: [],   
            loading: false
        })
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/rule/' + id,
            config
        ).then(response => {    
            this.setState({                
                severity: 'success',
                message: response.data.message,
                isAlert: true
            }) 
            this.componentDidMount()     
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
    }

    render() {

        const {
            rules,
            add,
            edit,
            open,
            name,
            accesses,
            userss,
            dashbord,
            parents,
            marks,
            notes,
            gradebooks,
            gradebookYear,
            propositionYear,
            galerie,
            announcement,
            year,
            fees,
            paiements,
            etats,
            cycleYear,
            classeYear,
            studentYear,
            compositionYear,
            subjectYear,
            users,
            rule,
            cycles,
            classes,
            students,
            compositions,
            subjects,
            albums,
            accounts,
            logs,

            loading,
            severity,
            message,
            isAlert,
            anchorEl,
            valuetab
        } = this.state

        let column = []
        if(this.props.accesses.approve){
            column = [
                { 
                    field: 'name', 
                    headerName: 'Nom',
                    flex: 1
                },
                { 
                    field: 'activated', 
                    headerName: 'Statut',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <Tooltip title={cellValues.row.activated ? "Statut actif" : "Statut non actif"}>
                                <IconButton onClick={() => this.delete(cellValues.row.id)} >   
                                    <Switch checked={cellValues.row.activated} />
                                </IconButton> 
                            </Tooltip>                            
                        )                                    
                    }
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }else{
            column = [
                { 
                    field: 'name', 
                    headerName: 'Nom',
                    flex: 1
                },
                { 
                    field: 'actions', 
                    headerName: 'Actions',
                    maxWidth: 100,
                    headerAlign: 'center',
                    align:'center',
                    renderCell: (cellValues) => {
                        return (
                            <React.Fragment>
                                <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                    <MoreVertIcon style={{color: "#306bff"}} />
                                </IconButton>

                                <Menu                                                            
                                    id={cellValues.row.id}
                                    anchorEl={anchorEl[cellValues.row.id]}
                                    keepMounted
                                    open={Boolean(anchorEl[cellValues.row.id])}
                                    onClose={() => this.setState({anchorEl: []})}    
                                    PaperProps={{
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 0,
                                            ml:1,
                                            width:150
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem  onClick={() => { this.show(cellValues.row.id) }}>
                                        <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                    </MenuItem>

                                    {!this.props.accesses.write ||
                                        <MenuItem onClick={() => { this.edit(cellValues.row.id) }} disabled={!this.props.accesses.write}>
                                            <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                        </MenuItem>
                                    }
                                </Menu>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }

        let DataRules=rules
        if(this.props.search !== ''){
            DataRules = DataRules.filter(elem => { return elem.name.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1;})
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} sx={{display:"flex",  justifyContent:"flex-end"}}>
                    {!this.props.accesses.write ||
                        <Button 
                            variant="contained" 
                            disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({add: true})} 
                            sx={{boxShadow: 10, borderRadius:1, bgcolor: '#306bff'}}
                        >
                            Ajouter un rôle
                        </Button>    
                    }
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                        <DataGrid
                            rows={DataRules}
                            columns={column}
                            elevation={10}
                            localeText={{
                                noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                MuiTablePagination: {
                                    labelRowsPerPage: 'Ligne par page'
                                }
                            }}
                        />
                    </Card>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <DialogTitle>Ajouter un rôle</DialogTitle>                    

                    <form role="form"  onSubmit={this.handleSubmit.bind(this)}>
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du rôle"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} mt={1}>
                                        <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Pages</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Lecture</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Écriture</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Approuver</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Suppression</Typography></th>                                               
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Sélectionner toutes les pages</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                dashbord.read && parents.read && marks.read && notes.read && 
                                                                gradebooks.read && gradebookYear.read && propositionYear.read && galerie.read && 
                                                                announcement.read && fees.read && paiements.read && etats.read && 
                                                                etats.read && cycleYear.read && classeYear.read && studentYear.read &&
                                                                compositionYear.read && subjectYear.read && users.read && rule.read &&
                                                                cycles.read && classes.read && students.read && compositions.read &&
                                                                subjects.read && albums.read && accounts.read && logs.read && year.read
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.read = e.target.checked;
                                                                marks.read = e.target.checked;
                                                                notes.read = e.target.checked;
                                                                gradebooks.read = e.target.checked;
                                                                gradebookYear.read = e.target.checked;
                                                                propositionYear.read = e.target.checked;
                                                                galerie.read = e.target.checked;
                                                                announcement.read = e.target.checked;
                                                                year.read = e.target.checked;
                                                                fees.read = e.target.checked;
                                                                paiements.read = e.target.checked;
                                                                etats.read = e.target.checked;
                                                                cycleYear.read = e.target.checked;
                                                                classeYear.read = e.target.checked;
                                                                studentYear.read = e.target.checked;
                                                                compositionYear.read = e.target.checked;
                                                                subjectYear.read = e.target.checked;
                                                                users.read = e.target.checked;
                                                                rule.read = e.target.checked;
                                                                cycles.read = e.target.checked;
                                                                classes.read = e.target.checked;
                                                                students.read = e.target.checked;
                                                                compositions.read = e.target.checked;
                                                                subjects.read = e.target.checked;
                                                                albums.read = e.target.checked;
                                                                accounts.read = e.target.checked;
                                                                logs.read = e.target.checked;
                                                                this.setState({ 
                                                                    dashbord: {read:e.target.checked}, parents, marks, notes, gradebooks, gradebookYear,
                                                                    propositionYear, galerie, announcement, fees, paiements, etats, cycleYear, classeYear,
                                                                    studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, logs, year
                                                                })
                                                            }}
                                                        /> 
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                parents.write && marks.write &&
                                                                galerie.write && 
                                                                announcement.write && fees.write && paiements.write &&
                                                                cycleYear.write && classeYear.write && studentYear.write &&
                                                                compositionYear.write && subjectYear.write && users.write && rule.write &&
                                                                cycles.write && classes.write && students.write && compositions.write &&
                                                                subjects.write && albums.write && accounts.write && year.write
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.write = e.target.checked;
                                                                marks.write = e.target.checked;
                                                                galerie.write = e.target.checked;
                                                                announcement.write = e.target.checked;
                                                                year.write = e.target.checked;
                                                                fees.write = e.target.checked;
                                                                paiements.write = e.target.checked;
                                                                cycleYear.write = e.target.checked;
                                                                classeYear.write = e.target.checked;
                                                                studentYear.write = e.target.checked;
                                                                compositionYear.write = e.target.checked;
                                                                subjectYear.write = e.target.checked;
                                                                users.write = e.target.checked;
                                                                rule.write = e.target.checked;
                                                                cycles.write = e.target.checked;
                                                                classes.write = e.target.checked;
                                                                students.write = e.target.checked;
                                                                compositions.write = e.target.checked;
                                                                subjects.write = e.target.checked;
                                                                albums.write = e.target.checked;
                                                                accounts.write = e.target.checked;
                                                                this.setState({ 
                                                                    parents, marks,
                                                                    galerie, announcement, fees, paiements, cycleYear, classeYear,
                                                                    studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, year
                                                                })
                                                            }}
                                                        /> 
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                parents.approve && rule.approve && users.approve && compositionYear.approve &&
                                                                announcement.approve && cycleYear.approve && classeYear.approve &&                                                     compositionYear.approve && users.approve && rule.approve &&
                                                                cycles.approve && classes.approve && students.approve && compositions.approve &&
                                                                subjects.approve && albums.approve && accounts.approve && year.approve
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.approve = e.target.checked;
                                                                announcement.approve = e.target.checked;
                                                                year.approve = e.target.checked;
                                                                cycleYear.approve = e.target.checked;
                                                                classeYear.approve = e.target.checked;
                                                                compositionYear.approve = e.target.checked;
                                                                users.approve = e.target.checked;
                                                                rule.approve = e.target.checked;
                                                                cycles.approve = e.target.checked;
                                                                classes.approve = e.target.checked;
                                                                students.approve = e.target.checked;
                                                                compositions.approve = e.target.checked;
                                                                subjects.approve = e.target.checked;
                                                                albums.approve = e.target.checked;
                                                                accounts.approve = e.target.checked;
                                                                this.setState({ 
                                                                    parents,
                                                                    announcement, cycleYear, classeYear,
                                                                    compositionYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, year
                                                                })
                                                            }}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                galerie.remove &&  parents.remove && subjectYear.remove && fees.remove && paiements.remove && studentYear.remove
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                subjectYear.remove = e.target.checked;
                                                                fees.remove = e.target.checked;
                                                                paiements.remove = e.target.checked;
                                                                galerie.remove = e.target.checked;
                                                                studentYear.remove = e.target.checked;
                                                                parents.remove = e.target.checked;
                                                                this.setState({ 
                                                                    parents, subjectYear, fees, paiements, studentYear, galerie
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Dashboard</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={dashbord.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Parents</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={parents.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.read = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={parents.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.write = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={parents.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.approve = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={parents.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.remove = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Notes par matière</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={marks.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { marks.read = e.target.checked; this.setState({ marks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={marks.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { marks.write = e.target.checked; this.setState({ marks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Notes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={notes.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { notes.read = e.target.checked; this.setState({ notes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Bulletin de notes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={gradebooks.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { gradebooks.read = e.target.checked; this.setState({ gradebooks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Bulletin annuel</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={gradebookYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { gradebookYear.read = e.target.checked; this.setState({ gradebookYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Propositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={propositionYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { propositionYear.read = e.target.checked; this.setState({ propositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Galerie</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={galerie.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.read = e.target.checked; this.setState({ galerie }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={galerie.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.write = e.target.checked; this.setState({ galerie }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={galerie.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.remove = e.target.checked; this.setState({ galerie }) } }
                                                        />                                              
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Annonces</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={announcement.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.read = e.target.checked; this.setState({ announcement }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={announcement.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.write = e.target.checked; this.setState({ announcement }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={announcement.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.approve = e.target.checked; this.setState({ announcement }) } }
                                                        />                                                     
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / Frais scolaire</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={fees.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.read = e.target.checked; this.setState({ fees }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={fees.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.write = e.target.checked; this.setState({ fees }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={fees.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.remove = e.target.checked; this.setState({ fees }) } }
                                                        />                                                  
                                                    </td>
                                                </tr>      

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / Paiements</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={paiements.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.read = e.target.checked; this.setState({ paiements }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={paiements.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.write = e.target.checked; this.setState({ paiements }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={paiements.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.remove = e.target.checked; this.setState({ paiements }) } }
                                                        />                                              
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / États</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={etats.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ etats: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>       

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Cyles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycleYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.read = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={cycleYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.write = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycleYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.approve = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Classes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classeYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.read = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={classeYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.write = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classeYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.approve = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>     

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Inscriptions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={studentYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.read = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={studentYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.write = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={studentYear.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.remove = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Compositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositionYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.read = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={compositionYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.write = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositionYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.approve = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Matières</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjectYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.read = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={subjectYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.write = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjectYear.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.remove = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>
                                                </tr>       

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Utilisateurs</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={users.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { users.read = e.target.checked; this.setState({ users }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={users.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { users.write = e.target.checked; this.setState({ users }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                        checked={users.approve} 
                                                        control={<Checkbox />}
                                                        onChange={e => { users.approve = e.target.checked; this.setState({ users }) } }
                                                    />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Rôles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={rule.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.read = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={rule.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.write = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={rule.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.approve = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Année académique</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={year.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.read = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={year.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.write = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={year.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.approve = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr> 

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Cycles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycles.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.read = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={cycles.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.write = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycles.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.approve = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Classes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classes.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.read = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={classes.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.write = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classes.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.approve = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Étudiants</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={students.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.read = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={students.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.write = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={students.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.approve = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Compositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositions.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.read = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={compositions.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.write = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositions.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.approve = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Matières</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjects.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.read = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={subjects.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.write = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjects.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.approve = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Albums</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={albums.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.read = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={albums.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.write = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={albums.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.approve = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Types de frais</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={accounts.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.read = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={accounts.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.write = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={accounts.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.approve = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Traces des activites</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={logs.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ logs: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>    
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({add: false})}>Annuller</Button>
                            <Button 
                                fullWidth 
                                type="submit" 
                                variant="contained" 
                            >
                                Cliquer pour ajouter
                            </Button>
                            {/* <Button  
                                type="submit" 
                                fullWidth
                                variant="contained" 
                                sx={{
                                    mt:2,
                                    color:"white", 
                                    textTransform: 'none', 
                                    fontSize:20, 
                                    height:'100%', 
                                    backgroundColor:'#888da0',  
                                    borderRadius:0, 
                                    ':hover':{backgroundColor:'#306bff'} 
                                }}
                            >
                                Cliquer pour ajouter
                            </Button>  */}
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">Éditer un rôle</DialogTitle>                    

                    <form role="form"  onSubmit={this.handleSubmitEdit.bind(this)}>
                        <DialogContent dividers={true}>
                            <DialogContentText tabIndex={-1} >
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du rôle"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange.bind(this)}
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12} mt={1}>
                                        <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Pages</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Lecture</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Écriture</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Approuver</Typography></th>
                                                    <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Suppression</Typography></th>                                               
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Sélectionner toutes les pages</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                dashbord.read && parents.read && marks.read && notes.read && 
                                                                gradebooks.read && gradebookYear.read && propositionYear.read && galerie.read && 
                                                                announcement.read && fees.read && paiements.read && etats.read && 
                                                                etats.read && cycleYear.read && classeYear.read && studentYear.read &&
                                                                compositionYear.read && subjectYear.read && users.read && rule.read &&
                                                                cycles.read && classes.read && students.read && compositions.read &&
                                                                subjects.read && albums.read && accounts.read && logs.read && year.read
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.read = e.target.checked;
                                                                marks.read = e.target.checked;
                                                                notes.read = e.target.checked;
                                                                gradebooks.read = e.target.checked;
                                                                gradebookYear.read = e.target.checked;
                                                                propositionYear.read = e.target.checked;
                                                                galerie.read = e.target.checked;
                                                                announcement.read = e.target.checked;
                                                                year.read = e.target.checked;
                                                                fees.read = e.target.checked;
                                                                paiements.read = e.target.checked;
                                                                etats.read = e.target.checked;
                                                                cycleYear.read = e.target.checked;
                                                                classeYear.read = e.target.checked;
                                                                studentYear.read = e.target.checked;
                                                                compositionYear.read = e.target.checked;
                                                                subjectYear.read = e.target.checked;
                                                                users.read = e.target.checked;
                                                                rule.read = e.target.checked;
                                                                cycles.read = e.target.checked;
                                                                classes.read = e.target.checked;
                                                                students.read = e.target.checked;
                                                                compositions.read = e.target.checked;
                                                                subjects.read = e.target.checked;
                                                                albums.read = e.target.checked;
                                                                accounts.read = e.target.checked;
                                                                logs.read = e.target.checked;
                                                                this.setState({ 
                                                                    dashbord: {read:e.target.checked}, parents, marks, notes, gradebooks, gradebookYear,
                                                                    propositionYear, galerie, announcement, fees, paiements, etats, cycleYear, classeYear,
                                                                    studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, logs, year
                                                                })
                                                            }}
                                                        /> 
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                parents.write && marks.write &&
                                                                galerie.write && 
                                                                announcement.write && fees.write && paiements.write &&
                                                                cycleYear.write && classeYear.write && studentYear.write &&
                                                                compositionYear.write && subjectYear.write && users.write && rule.write &&
                                                                cycles.write && classes.write && students.write && compositions.write &&
                                                                subjects.write && albums.write && accounts.write && year.write
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.write = e.target.checked;
                                                                marks.write = e.target.checked;
                                                                galerie.write = e.target.checked;
                                                                announcement.write = e.target.checked;
                                                                year.write = e.target.checked;
                                                                fees.write = e.target.checked;
                                                                paiements.write = e.target.checked;
                                                                cycleYear.write = e.target.checked;
                                                                classeYear.write = e.target.checked;
                                                                studentYear.write = e.target.checked;
                                                                compositionYear.write = e.target.checked;
                                                                subjectYear.write = e.target.checked;
                                                                users.write = e.target.checked;
                                                                rule.write = e.target.checked;
                                                                cycles.write = e.target.checked;
                                                                classes.write = e.target.checked;
                                                                students.write = e.target.checked;
                                                                compositions.write = e.target.checked;
                                                                subjects.write = e.target.checked;
                                                                albums.write = e.target.checked;
                                                                accounts.write = e.target.checked;
                                                                this.setState({ 
                                                                    parents, marks,
                                                                    galerie, announcement, fees, paiements, cycleYear, classeYear,
                                                                    studentYear, compositionYear, subjectYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, year
                                                                })
                                                            }}
                                                        /> 
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                parents.approve && rule.approve && users.approve && compositionYear.approve &&
                                                                announcement.approve && cycleYear.approve && classeYear.approve &&                                                     compositionYear.approve && users.approve && rule.approve &&
                                                                cycles.approve && classes.approve && students.approve && compositions.approve &&
                                                                subjects.approve && albums.approve && accounts.approve && year.approve
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                parents.approve = e.target.checked;
                                                                announcement.approve = e.target.checked;
                                                                year.approve = e.target.checked;
                                                                cycleYear.approve = e.target.checked;
                                                                classeYear.approve = e.target.checked;
                                                                compositionYear.approve = e.target.checked;
                                                                users.approve = e.target.checked;
                                                                rule.approve = e.target.checked;
                                                                cycles.approve = e.target.checked;
                                                                classes.approve = e.target.checked;
                                                                students.approve = e.target.checked;
                                                                compositions.approve = e.target.checked;
                                                                subjects.approve = e.target.checked;
                                                                albums.approve = e.target.checked;
                                                                accounts.approve = e.target.checked;
                                                                this.setState({ 
                                                                    parents,
                                                                    announcement, cycleYear, classeYear,
                                                                    compositionYear, users, rule, cycles, classes, students,
                                                                    compositions, subjects, albums, accounts, year
                                                                })
                                                            }}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={
                                                                galerie.remove && parents.remove && subjectYear.remove && fees.remove && paiements.remove && studentYear.remove
                                                            }
                                                            control={<Checkbox />}
                                                            onChange={e => {
                                                                subjectYear.remove = e.target.checked;
                                                                fees.remove = e.target.checked;
                                                                paiements.remove = e.target.checked;
                                                                galerie.remove = e.target.checked;
                                                                studentYear.remove = e.target.checked;
                                                                parents.remove = e.target.checked;
                                                                this.setState({ 
                                                                    parents, subjectYear, fees, paiements, studentYear, galerie
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Dashboard</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={dashbord.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Parents</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={parents.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.read = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={parents.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.write = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={parents.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.approve = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={parents.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { parents.remove = e.target.checked; this.setState({ parents }) } }
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Notes par matière</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={marks.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { marks.read = e.target.checked; this.setState({ marks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={marks.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { marks.write = e.target.checked; this.setState({ marks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Notes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={notes.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { notes.read = e.target.checked; this.setState({ notes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Bulletin de notes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={gradebooks.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { gradebooks.read = e.target.checked; this.setState({ gradebooks }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Bulletin annuel</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={gradebookYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { gradebookYear.read = e.target.checked; this.setState({ gradebookYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Notes / Propositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={propositionYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { propositionYear.read = e.target.checked; this.setState({ propositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>    

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Galerie</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={galerie.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.read = e.target.checked; this.setState({ galerie }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={galerie.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.write = e.target.checked; this.setState({ galerie }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={galerie.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { galerie.remove = e.target.checked; this.setState({ galerie }) } }
                                                        />                                              
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Annonces</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={announcement.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.read = e.target.checked; this.setState({ announcement }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={announcement.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.write = e.target.checked; this.setState({ announcement }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={announcement.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { announcement.approve = e.target.checked; this.setState({ announcement }) } }
                                                        />                                                     
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / Frais scolaire</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={fees.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.read = e.target.checked; this.setState({ fees }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={fees.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.write = e.target.checked; this.setState({ fees }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={fees.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { fees.remove = e.target.checked; this.setState({ fees }) } }
                                                        />                                                  
                                                    </td>
                                                </tr>      

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / Paiements</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={paiements.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.read = e.target.checked; this.setState({ paiements }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={paiements.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.write = e.target.checked; this.setState({ paiements }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={paiements.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { paiements.remove = e.target.checked; this.setState({ paiements }) } }
                                                        />                                              
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Comptabilité / États</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={etats.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ etats: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>       

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Cyles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycleYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.read = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={cycleYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.write = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycleYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycleYear.approve = e.target.checked; this.setState({ cycleYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Classes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classeYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.read = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={classeYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.write = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classeYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classeYear.approve = e.target.checked; this.setState({ classeYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>     

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Inscriptions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={studentYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.read = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={studentYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.write = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={studentYear.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { studentYear.remove = e.target.checked; this.setState({ studentYear }) } }
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Compositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositionYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.read = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={compositionYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.write = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositionYear.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositionYear.approve = e.target.checked; this.setState({ compositionYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>  

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Administration / Matières</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjectYear.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.read = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={subjectYear.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.write = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjectYear.remove} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjectYear.remove = e.target.checked; this.setState({ subjectYear }) } }
                                                        />
                                                    </td>
                                                </tr>       

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Utilisateurs</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={users.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { users.read = e.target.checked; this.setState({ users }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={users.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { users.write = e.target.checked; this.setState({ users }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                        checked={users.approve} 
                                                        control={<Checkbox />}
                                                        onChange={e => { users.approve = e.target.checked; this.setState({ users }) } }
                                                    />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Rôles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={rule.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.read = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={rule.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.write = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={rule.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { rule.approve = e.target.checked; this.setState({ rule }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>   

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Année académique</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={year.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.read = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={year.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.write = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={year.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { year.approve = e.target.checked; this.setState({ year }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr> 

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Cycles</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycles.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.read = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={cycles.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.write = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={cycles.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { cycles.approve = e.target.checked; this.setState({ cycles }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Classes</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classes.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.read = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={classes.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.write = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={classes.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { classes.approve = e.target.checked; this.setState({ classes }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Étudiants</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={students.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.read = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={students.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.write = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={students.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { students.approve = e.target.checked; this.setState({ students }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Compositions</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositions.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.read = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={compositions.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.write = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={compositions.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { compositions.approve = e.target.checked; this.setState({ compositions }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Matières</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjects.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.read = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={subjects.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.write = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={subjects.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { subjects.approve = e.target.checked; this.setState({ subjects }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Albums</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={albums.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.read = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={albums.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.write = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={albums.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { albums.approve = e.target.checked; this.setState({ albums }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Paramètres / Types de frais</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={accounts.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.read = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}> 
                                                        <FormControlLabel
                                                            checked={accounts.write} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.write = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={accounts.approve} 
                                                            control={<Checkbox />}
                                                            onChange={e => { accounts.approve = e.target.checked; this.setState({ accounts }) } }
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>Traces des activites</Typography>
                                                    </td>
                                                    
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={logs.read} 
                                                            control={<Checkbox />}
                                                            onChange={e => this.setState({ logs: {read:e.target.checked} })}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>

                                                    <td style={{ textAlign: 'center' }}>
                                                        <FormControlLabel
                                                            checked={false} 
                                                            control={<Checkbox  disabled />}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>    
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({edit: false})}>Annuller</Button>
                            <Button 
                                fullWidth 
                                type="submit" 
                                variant="contained" 
                            >
                                Cliquer pour modifier
                            </Button>
                        </DialogActions>                          
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">{name}</DialogTitle>                    

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TabContext value={valuetab}  >
                                        <Box sx={{  borderColor: 'divider'}}>
                                            <TabList onChange={this.handleChangetab.bind(this)} variant="scrollable" scrollButtons="auto">
                                                <Tab label="Rôle et permissions" value="1" />       
                                                <Tab label="Comptes associés" value="2" />
                                            </TabList>
                                        </Box>

                                        <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                                <Grid item xs={12} md={12} mt={1}>
                                                    <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"><Typography color="text.secondary" sx={{fontSize:16 }}>Pages</Typography></th>
                                                                <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Lecture</Typography></th>
                                                                <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Écriture</Typography></th>
                                                                <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Approuver</Typography></th>
                                                                <th scope="col" style={{ textAlign: 'center' }}><Typography color="text.secondary" sx={{fontSize:16 }}>Suppression</Typography></th>                                               
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {accesses.map((item) => ( 
                                                                <tr>
                                                                    <td>
                                                                        <Typography color="text.secondary" sx={{fontSize:16 }}>
                                                                            {item.module === 'dashbord' && 'Dashboard:'}
                                                                            {item.module === 'parents' && 'Parents:'}
                                                                            {item.module === 'marks' && 'Notes / Notes par matière:'}
                                                                            {item.module === 'notes' && 'Notes / Notes:'}
                                                                            {item.module === 'gradebooks' && 'Notes / Bulletin de notes:'}
                                                                            {item.module === 'gradebookYear' && 'Notes / Bulletin annuel:'}
                                                                            {item.module === 'propositionYear' && 'Notes / Propositions:'}
                                                                            {item.module === 'galerie' && 'Galerie:'}
                                                                            {item.module === 'announcement' && 'Annonces:'}
                                                                            {item.module === 'fees' && 'Comptabilité / Frais scolaire:'}
                                                                            {item.module === 'paiements' && 'Comptabilité / Paiements:'}
                                                                            {item.module === 'etats' && 'Comptabilité / États:'}
                                                                            {item.module === 'cycleYear' && 'Administration / Cyles:'}
                                                                            {item.module === 'classeYear' && 'Administration / Classes:'}
                                                                            {item.module === 'studentYear' && 'Administration / Inscriptions:'}
                                                                            {item.module === 'compositionYear' && 'Administration / Compositions:'}
                                                                            {item.module === 'subjectYear' && 'Administration / Matières:'}
                                                                            {item.module === 'users' && 'Utilisateurs:'}
                                                                            {item.module === 'rule' && 'Paramètres / Rôles:'}
                                                                            {item.module === 'cycles' && 'Paramètres / Cycles:'}
                                                                            {item.module === 'classes' && 'Paramètres / Classes:'}
                                                                            {item.module === 'students' && 'Paramètres / Étudiants:'}
                                                                            {item.module === 'compositions' && 'Paramètres / Compositions:'}
                                                                            {item.module === 'subjects' && 'Paramètres / Matières:'}
                                                                            {item.module === 'albums' && 'Paramètres / Albums:'}
                                                                            {item.module === 'accounts' && 'Paramètres / Types de frais:'}
                                                                            {item.module === 'logs' && 'Traces des activites:'}
                                                                            {item.module === 'year' && 'Année académique:'}
                                                                        </Typography>
                                                                    </td>
                                                                    
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {item.read == undefined ?
                                                                            <FormControlLabel
                                                                                checked={false} 
                                                                                control={<Checkbox  disabled />}
                                                                            />
                                                                        :
                                                                            <FormControlLabel
                                                                                checked={item.read} 
                                                                                control={<Checkbox disabled />}
                                                                            />
                                                                        }
                                                                    </td>

                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {item.write == undefined ?
                                                                            <FormControlLabel
                                                                                checked={false} 
                                                                                control={<Checkbox  disabled />}
                                                                            />
                                                                        :
                                                                            <FormControlLabel
                                                                                checked={item.write} 
                                                                                control={<Checkbox disabled />}
                                                                            />
                                                                        }
                                                                    </td>

                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {item.approve == undefined ?
                                                                            <FormControlLabel
                                                                                checked={false} 
                                                                                control={<Checkbox  disabled />}
                                                                            />
                                                                        :
                                                                            <FormControlLabel
                                                                                checked={item.approve} 
                                                                                control={<Checkbox disabled />}
                                                                            />
                                                                        }
                                                                    </td>

                                                                    <td style={{ textAlign: 'center' }}>
                                                                        {item.remove == undefined ?
                                                                            <FormControlLabel
                                                                                checked={false} 
                                                                                control={<Checkbox  disabled />}
                                                                            />
                                                                        :
                                                                            <FormControlLabel
                                                                                checked={item.remove} 
                                                                                control={<Checkbox disabled />}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Matricule</th>
                                                        <th scope="col">Prénom</th>
                                                        <th scope="col">Nom</th>
                                                        <th scope="col">Rôle</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {userss.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.matricule}</td>
                                                                <td>{item.first_name}</td>
                                                                <td>{item.second_name}</td>
                                                                <td>{item.rule.name}</td> 
                                                            </tr>
                                                        )
                                                    })} 
                                                </tbody>
                                            </table>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button fullWidth onClick={() => this.setState({open: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}
