import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import axios from 'axios';
import AlertNotification from './AlertNotification'
import {MainUrl, SchoolName} from '../settings'
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import Tooltip from '@mui/material/Tooltip';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TaskIcon from '@mui/icons-material/Task';
import EditNoteIcon from '@mui/icons-material/EditNote';
import NoteIcon from '@mui/icons-material/Note';
import ApprovalIcon from '@mui/icons-material/Approval';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import BlockIcon from '@mui/icons-material/Block';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import TopicIcon from '@mui/icons-material/Topic';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SettingsIcon from '@mui/icons-material/Settings';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DoorSlidingSharpIcon from '@mui/icons-material/DoorSlidingSharp';
import SubjectIcon from '@mui/icons-material/Subject';
import LayersIcon from '@mui/icons-material/Layers';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TimelineIcon from '@mui/icons-material/Timeline';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import SelectYear from "./SelectYear"
import Logout from '@mui/icons-material/Logout';


import Dashboard from "./pages/Dashboard";
import Tuteurs from "./pages/Tuteurs"
import AlbumYears from "./pages/AlbumYears"
import Marks from "./pages/Marks"
import Notes from "./pages/Notes";
import Gradebook from "./pages/Gradebook"
import GradebookYear from "./pages/GradebookYear"
import Propositions from "./pages/Propositions"
import CycleYear from "./pages/CycleYear"
import ClasseYear from "./pages/ClasseYear"
import StudentYear from "./pages/StudentYear"
import CompositionYear from "./pages/CompositionYear"
import SubjectYear from "./pages/SubjectYear"
import Announcements from "./pages/Announcements"
import Users from "./pages/Users"
import Fees from "./pages/Fees"
import Rules from "./pages/Rules";
import Years from "./pages/Years";
import Cycles from "./pages/Cycles";
import Classes from "./pages/Classes"
import Subjects from "./pages/Subjects"
import Compositions from "./pages/Compositions"
import Albums from "./pages/Albums"
import Absences from "./pages/Absences"
import Students from "./pages/Students";
import Accounts from "./pages/Accounts"
import Payments from "./pages/Payments"
import Etats from "./pages/Etats"
import Logs from "./pages/Logs"
import Profil from "./pages/Profil"

import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
    marginRight:16,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f1f1f1', //alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: '#f5f5f5', //alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open:true,    
            year_id: null,

            marks: false,
            accounting: false,  
            administration: false, 
            parametres: false,

            search:'',

            pagetitle: "",
            fname: "",
            sname: "",
            matricule: "",
            item: window.location.pathname,
            type: "",
            accesses: [],

            severity: "",
            isAlert:false,
            loading: true,   

            anchorEl: null
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("USER"))
        const type = JSON.parse(localStorage.getItem("TYPE"))        
        if (user === null) {
            window.location.href = '/';
        } else if(type === 'user'){
            const accesses = JSON.parse(localStorage.getItem("ACCESSES"))
            this.setState({
                fname: user.first_name, 
                sname: user.second_name, 
                matricule: user.matricule,
                type: type,
                accesses: accesses,
            });
            this.setUrl(this.state.item)
        }else{
            this.setState({
                fname: user.first_name, 
                sname: user.second_name, 
                matricule: user.matricule,
                type: type,
            });
            this.setUrl(this.state.item)

        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    setUrl(item) {
        this.setState({ item:item })
        if (item === "/dashboard") {
            this.setState({ pagetitle: "Dashboard" })
        }
        else if (item === "/marks") {   
            this.setState({ pagetitle: "Notes par matière" })
        }
        else if (item === "/notes") {   
            this.setState({ pagetitle: "Notes" })
        }
        else if (item === "/gradebooks") {   
            this.setState({ pagetitle: "Bulletin de notes" })
        }
        else if (item === "/gradebook-year") {   
            this.setState({ pagetitle: "Bulletin annuel" })
        }
        else if (item === "/proposition-year") {   
            this.setState({ pagetitle: "Proposition de passage, redoublement et d'exclusion" })
        }
        else if (item === "/cycle-year") {   
            this.setState({ pagetitle: "Cycles" })
        }
        else if (item === "/classe-year") {   
            this.setState({ pagetitle: "Classes" })
        }
        else if (item === "/student-year") {   
            this.setState({ pagetitle: "Inscriptions" })
        }
        else if (item === "/composition-year") {   
            this.setState({ pagetitle: "Compositions" })
        }
        else if (item === "/subject-year") {   
            this.setState({ pagetitle: "Matières" })
        }
        else if (item === "/rules") {   
            this.setState({ pagetitle: "Rôles et permissions" })
        } else if (item === "/years") {   
            this.setState({ pagetitle: "Année académique" })
        } 
        else if (item === "/cycles") {   
            this.setState({ pagetitle: "Cycles scolaires" })
        } 
        else if (item === "/classes") {   
            this.setState({ pagetitle: "Classes scolaires" })
        }         
        else if (item === "/subjects") {   
            this.setState({ pagetitle: "Matières scolaires" })
        } 
        else if (item === "/compositions") {   
            this.setState({ pagetitle: "Compositions scolaires" })
        }
        else if (item === "/users") {   
            this.setState({ pagetitle: "Utilisateurs" })
        } 
        else if (item === "/fees") {   
            this.setState({ pagetitle: "Frais scolaire" })
        }
        else if (item === "/galerie") {   
            this.setState({ pagetitle: "Galerie" })
        } 
        else if (item === "/announcement") {   
            this.setState({ pagetitle: "Annonces" })
        }
        else if (item === "/albums") {   
            this.setState({ pagetitle: "Albums scolaires" })
        } 
        else if (item === "/absences") {   
            this.setState({ pagetitle: "Absences" })
        } 
        else if (item === "/students") {   
            this.setState({ pagetitle: "Étudiants" })
        } 
        else if (item === "/parents") {   
            this.setState({ pagetitle: "Parents" })
        } 
        else if (item === "/accounts") {   
            this.setState({ pagetitle: "Types de frais" })
        }
        else if (item === "/paiements") {   
            this.setState({ pagetitle: "Paiements" })
        }
        else if (item === "/etats") {   
            this.setState({ pagetitle: "États" })
        }
        else if (item === "/logs") {   
            this.setState({ pagetitle: "Traces des activites des utilisateurs" })
        } 
        else if (item === "/profil") {   
            this.setState({ pagetitle: "Gérer votre compte" })
        } 
    }

    handleClick(event){this.setState({anchorEl: event.currentTarget}) }

    isAlertChange(){ this.setState({isAlert:false}) }

    yearChange(id){ this.setState({year_id:id}) }

    logout() {
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(MainUrl() + '/api/v1/logout',
            config
        ).then(response => {    
            localStorage.clear();
            window.location.href = '/';    
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data?.message || "Une erreur inattendue s'est produite",
                isAlert: true
            });
        });
        
    }

    render() {

        const {
            type,
            accesses,
            open,
            year_id,
            pagetitle,
            item,
            search,
            fname,
            sname,
            matricule,
            marks,
            accounting,
            administration,
            parametres,

            loading,
            severity,
            message,
            isAlert,
            anchorEl
        } = this.state

        let permissions = {
            "type": type,
            "module": "item",
            "read": false,
            "write": false,
            "approve": false,
            "remove": false
        }

        return (
            <BrowserRouter >
                <Box sx={{ display: 'flex' }}>
                    <WhatsAppWidget 
                        CompanyIcon={WhatsAppIcon}
                        phoneNumber="+22368176969" 
                        companyName="SUPPORT GESCO"
                        message="DITES-NOUS EN QUOI NOUS POUVONS VOUS AIDER"
                        sendButtonText="ENVOYEZ"
                        replyTimeText=""
                    />

                    <AppBar position="fixed" color="inherit" open={open} elevation={0}>
                        <Toolbar sx={{ pr: '24px' }} >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => this.setState({open: !open})}
                                sx={{ mr: 2}}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                            >
                                {pagetitle}
                            </Typography>

                            <Box sx={{ flexGrow: 1 }} />

                            {( item == "/absences" ||  item == "/parents" || item == "/galerie" || item == "/announcement" || item == "/paiements" || item == "/fees" || item == "/accounts" || item == "/proposition-year" || item == "/marks" || item == "/subject-year" || item == "/student-year"  || item == "/composition-year"  || item == "/compositions" || item == "/classe-year" || item == "/cycle-year" ||  item == "/students" || item == "/users" || item == "/logs" || item == "/rules"  || item == "/years" || item == "/cycles" || item == "/classes" || item == "/subjects"  || item == "/albums") &&
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>

                                    <StyledInputBase
                                        variant="standard" 
                                        name="search"
                                        placeholder="Mot-clé"
                                        value={search}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Search>
                            }          
                            
                            {( item == "/absences" || item == "/dashboard" || item == "/galerie" || item == "/announcement" || item == "/etats" || item == "/paiements" || item == "/fees" || item == "/proposition-year" || item == "/gradebook-year" || item == "/gradebooks" || item == "/marks" || item == "/notes" || item == "/subject-year" || item == "/student-year"  || item == "/composition-year"  ||  item == "/classe-year" || item == "/cycle-year") && <SelectYear onChange={this.yearChange.bind(this)} style={{marginRight: 32}} />} 

                            <Tooltip 
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit" sx={{fontSize:14, fontWeight: 800}}>Compte {SchoolName()}</Typography>
                                        <Typography color="inherit" sx={{fontSize:12}}>{fname + " " + sname}</Typography>
                                        <Typography color="inherit" sx={{fontSize:12}}>{matricule}</Typography>
                                    </React.Fragment>
                                }
                            >
                                <IconButton color="inherit" onClick={this.handleClick.bind(this)}>
                                    <Avatar sx={{bgcolor: '#306bff',  mr:1 }}>{fname.charAt(0)}{sname.charAt(0)}</Avatar>
                                </IconButton>       
                            </Tooltip>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => {this.setState({ anchorEl: null})}}
                                MenuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                        sx: {
                                            width:280,                               overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                            },
                                            '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,

                                            },
                                        },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Link to="/profil"  onClick={() => this.setUrl("/profil")} style={{textDecoration: 'none', color: "inherit"}}>
                                    <MenuItem >
                                        <Avatar sx={{bgcolor: '#306bff',  mr:1 }}>{fname.charAt(0)}{sname.charAt(0)}</Avatar> Profile
                                    </MenuItem>
                                </Link>
                                

                                <Divider />

                                <MenuItem onClick={() => this.logout()}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Se deconnecter
                                </MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <DrawerHeader>
                            <Typography 
                                component="h1" 
                                variant="h6" 
                                noWrap 
                                color="text.secondary" 
                                sx={{fontWeight: 800}}
                            >
                               {SchoolName()}
                            </Typography>
                        </DrawerHeader>

                        <Divider />

                        {type === 'user' &&
                            <List sx={{paddingBottom:8}}>
                                <Link to="/dashboard"  onClick={() => this.setUrl("/dashboard")}  style={{textDecoration: 'none', color: "inherit"}}>
                                    <ListItem key={'item'} disablePadding style={item === "/dashboard" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} > 
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <DataUsageIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Dashboard'}   />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                {accesses[1].read &&
                                    <Link to="/parents"  onClick={() => this.setUrl("/parents")}  style={{textDecoration: 'none', color: "inherit"}}>
                                        <ListItem key={'parents'} disablePadding style={item === "/parents" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} > 
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <PeopleAltIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Parents'}   />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>     
                                }   

                                {(accesses[2].read || accesses[3].read || accesses[4].read || accesses[5].read || accesses[6].read) &&
                                    <ListItem key={'marks'} disablePadding>
                                        <ListItemButton onClick={() => this.setState({marks: !marks})}>
                                            <ListItemIcon>
                                                <NoteAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Notes'}  />
                                            {marks ? <ExpandLess  /> : <ExpandMore />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={marks} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {accesses[2].read &&
                                            <Link to="/marks"  onClick={() => this.setUrl("/marks")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'marks'} disablePadding style={item === "/marks" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                               
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <NoteAddIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Notes par matière"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {accesses[3].read &&
                                            <Link to="/notes"  onClick={() => this.setUrl("/notes")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'notes'} disablePadding style={item === "/notes" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <FindInPageIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Notes"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[4].read &&
                                            <Link to="/gradebooks"  onClick={() => this.setUrl("/gradebooks")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'gradebooks'} disablePadding style={item === "/gradebooks" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <TaskIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Bulletin de notes"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[5].read &&
                                            <Link to="/gradebook-year"  onClick={() => this.setUrl("/gradebook-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'gradebook-year'} disablePadding style={item === "/gradebook-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <NoteIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Bulletins annuel"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[6].read &&
                                            <Link to="/proposition-year"  onClick={() => this.setUrl("/proposition-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'proposition-year'} disablePadding style={item === "/proposition-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                                             
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <ApprovalIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Propositions"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                    </List>
                                </Collapse>

                                {accesses[7].read &&
                                    <Link to="/galerie"  onClick={() => this.setUrl("/galerie")}  style={{textDecoration: 'none', color: "inherit"}}>
                                        <ListItem key={'galerie'} disablePadding style={item === "/galerie" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <PhotoAlbumIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Galerie'}   />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }
                                
                                {accesses[8].read &&
                                    <Link to="/announcement"  onClick={() => this.setUrl("/announcement")}  style={{textDecoration: 'none', color: "inherit"}}>
                                        <ListItem key={'item'} disablePadding style={item === "/announcement" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <NotificationsActiveOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Annonces'}   />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                <Link to="/absences"  onClick={() => this.setUrl("/absences")}  style={{textDecoration: 'none', color: "inherit"}}>
                                    <ListItem key={'item'} disablePadding style={item === "/absences" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <BlockIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Absences'}   />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                {(accesses[9].read || accesses[10].read || accesses[11].read) &&
                                    <ListItem key={'accounting'} disablePadding>
                                        <ListItemButton onClick={() => this.setState({accounting: !accounting})}>
                                            <ListItemIcon>
                                                <CalculateIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Comptabilité'}  />
                                            {accounting ? <ExpandLess  /> : <ExpandMore />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={accounting} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {accesses[9].read &&
                                            <Link to="/fees"  onClick={() => this.setUrl("/fees")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'fees'} disablePadding style={item === "/fees" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                  
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <ShoppingCartCheckoutIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Frais scolaire"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[10].read &&
                                            <Link to="/paiements"  onClick={() => this.setUrl("/paiements")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'paiements'} disablePadding style={item === "/paiements" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                  
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <PaymentIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Paiements"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        } 
                                        
                                        {accesses[11].read &&
                                            <Link to="/etats"  onClick={() => this.setUrl("/etats")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'etats'} disablePadding style={item === "/etats" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                  
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <CreditScoreIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="États"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                    </List>
                                </Collapse>

                                {(accesses[12].read || accesses[13].read || accesses[14].read || accesses[15].read || accesses[16].read) &&                                
                                    <ListItem key={'administration'} disablePadding>
                                        <ListItemButton onClick={() => this.setState({administration: !administration})}>
                                            <ListItemIcon>
                                                <ManageAccountsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Administration'}  />
                                            {administration ? <ExpandLess  /> : <ExpandMore />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={administration} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {accesses[12].read &&
                                            <Link to="/cycle-year"  onClick={() => this.setUrl("/cycle-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'item'} disablePadding style={item === "/cycle-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <ChangeCircleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Cycles"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {accesses[13].read &&
                                            <Link to="/classe-year"  onClick={() => this.setUrl("/classe-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'classe-year'} disablePadding style={item === "/classe-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <DoorSlidingSharpIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Classes"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[14].read &&
                                            <Link to="/student-year"  onClick={() => this.setUrl("/student-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'student-year'} disablePadding style={item === "/student-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <EditNoteIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Inscriptions"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[15].read &&
                                            <Link to="/composition-year"  onClick={() => this.setUrl("/composition-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'composition-year'} disablePadding style={item === "/composition-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <LayersIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Compositions"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[16].read &&
                                            <Link to="/subject-year"  onClick={() => this.setUrl("/subject-year")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'subject-year'} disablePadding style={item === "/subject-year" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                           
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <SubjectIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Matières"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                    </List>
                                </Collapse>

                                {accesses[17].read &&
                                    <Link to="/users"  onClick={() => this.setUrl("/users")}  style={{textDecoration: 'none', color: "inherit"}}>
                                        <ListItem key={'users'} disablePadding style={item === "/users" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <GroupRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Utilisateurs'}   />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }


                                {(accesses[27].read || accesses[18].read || accesses[19].read || accesses[20].read || accesses[21].read || accesses[22].read || accesses[23].read || accesses[24].read || accesses[25].read) &&
                                    <ListItem key={'Parametres'} disablePadding>
                                        <ListItemButton onClick={() => this.setState({parametres: !parametres})}>
                                            <ListItemIcon>
                                                <SettingsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Paramètres'}  />
                                            {parametres ? <ExpandLess  /> : <ExpandMore />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={parametres} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {accesses[18].read &&
                                            <Link to="/rules"  onClick={() => this.setUrl("/rules")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'rules'} disablePadding style={item === "/rules" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <SettingsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Rôles"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {accesses[27].read &&
                                            <Link to="/years"  onClick={() => this.setUrl("/years")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'years'} disablePadding style={item === "/years" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                              
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <TopicIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Année académique"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[19].read &&
                                            <Link to="/cycles"  onClick={() => this.setUrl("/cycles")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'cycles'} disablePadding style={item === "/cycles" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                             
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <ChangeCircleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Cycles"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[20].read &&
                                            <Link to="/classes"  onClick={() => this.setUrl("/classes")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'classes'} disablePadding style={item === "/classes" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <DoorSlidingSharpIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Classes"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                        
                                        {accesses[21].read &&                                        
                                            <Link to="/students"  onClick={() => this.setUrl("/students")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'students'} disablePadding style={item === "/students" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                              
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <PeopleAltIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Étudiants"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>  
                                        }
                                        
                                        {accesses[22].read &&                                        
                                            <Link to="/compositions"  onClick={() => this.setUrl("/compositions")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'compositions'} disablePadding style={item === "/compositions" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >  
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <LayersIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Compositions"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link> 
                                        }
                                        
                                        {accesses[23].read &&
                                            <Link to="/subjects"  onClick={() => this.setUrl("/subjects")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'subjects'} disablePadding style={item === "/subjects" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                              
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <SubjectIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Matières"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>   
                                        }
                                        
                                        {accesses[24].read &&
                                            <Link to="/albums"  onClick={() => this.setUrl("/albums")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'albums'} disablePadding style={item === "/albums" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} > 
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <PhotoAlbumIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Albums"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {accesses[25].read &&                                        
                                            <Link to="/accounts"  onClick={() => this.setUrl("/accounts")}  style={{textDecoration: 'none', color: "inherit"}}>
                                                <ListItem key={'accounts'} disablePadding style={item === "/accounts" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                              
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <LocalOfferIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Types de frais"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>      
                                        }                             
                                    </List>
                                </Collapse>

                                {accesses[26].read &&
                                    <Link to="/logs"  onClick={() => this.setUrl("/logs")}  style={{textDecoration: 'none', color: "inherit"}}>
                                        <ListItem key={'logs'} disablePadding style={item === "/logs" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <TimelineIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={'Traces des activites'}  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }
                            </List>
                        }       

                        {(type === 'student' || type === 'parent') &&
                            <List sx={{paddingBottom:8}}>
                                <Link to="/dashboard"  onClick={() => this.setUrl("/dashboard")}  style={{textDecoration: 'none', color: "inherit"}}>
                                    <ListItem key={'item'} disablePadding style={item === "/dashboard" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} > 
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <DataUsageIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Dashboard'}   />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <Link to="/galerie"  onClick={() => this.setUrl("/galerie")}  style={{textDecoration: 'none', color: "inherit"}}>
                                    <ListItem key={'galerie'} disablePadding style={item === "/galerie" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <PhotoAlbumIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Galerie'}   />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>

                                <ListItem key={'accounting'} disablePadding>
                                    <ListItemButton onClick={() => this.setState({accounting: !accounting})}>
                                        <ListItemIcon>
                                            <CalculateIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={'Comptabilité'}  />
                                        {accounting ? <ExpandLess  /> : <ExpandMore />}
                                    </ListItemButton> 
                                </ListItem>

                                <Collapse in={accounting} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <Link to="/fees"  onClick={() => this.setUrl("/fees")}  style={{textDecoration: 'none', color: "inherit"}}>
                                            <ListItem key={'fees'} disablePadding style={item === "/fees" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                  
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <ShoppingCartCheckoutIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Frais scolaire"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                </ListItemButton>
                                            </ListItem>
                                        </Link>

                                        <Link to="/etats"  onClick={() => this.setUrl("/etats")}  style={{textDecoration: 'none', color: "inherit"}}>
                                            <ListItem key={'etats'} disablePadding style={item === "/etats" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                                  
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemIcon>
                                                        <CreditScoreIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="États"  primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}} sx={{ ml: -2 }} />
                                                </ListItemButton>
                                            </ListItem>
                                        </Link>
                                    </List>
                                </Collapse>

                                <Link to="/students"  onClick={() => this.setUrl("/students")}  style={{textDecoration: 'none', color: "inherit"}}>
                                    <ListItem key={'students'} disablePadding style={item === "/students" ? {backgroundColor:'rgba(0, 0, 0, 0.12)'} : null} >                              
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <PeopleAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Étudiants" />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            </List>
                        }              
                        
                        <span style={{position: 'fixed', bottom: 0, paddingBottom:24, paddingTop: 24, width: drawerWidth, backgroundColor: '#fff'}}>
                            <Typography 
                                variant="body1"
                                noWrap 
                                color="text.secondary" 
                                align="center"
                            >
                                © 2024 Ahmed HAIDARA
                            </Typography>    
                        </span>                          
                    </Drawer>   

                    <Main open={open} sx={{ overflowX:'auto', minHeight: `calc(100vh - 0px)`, backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900] }} >
                        <DrawerHeader />

                        {type === 'user' &&
                            <Routes>  
                                <Route path="dashboard"  element={<Dashboard year_id={year_id} accesses={accesses[0]} />} />

                                <Route path="absences" element={<Absences search={search} year_id={year_id} />} />

                                {accesses[1].read && <Route path="parents" element={<Tuteurs search={search} year_id={year_id} accesses={accesses[1]} />} />}

                                {accesses[2].read && <Route path="marks" element={<Marks search={search} year_id={year_id} accesses={accesses[2]} />} />}

                                {accesses[3].read && <Route path="notes" element={<Notes search={search} year_id={year_id} accesses={accesses[3]} />} />}

                                {accesses[4].read && <Route path="gradebooks" element={<Gradebook search={search} year_id={year_id} accesses={accesses[4]} />} />}

                                {accesses[5].read && <Route path="gradebook-year" element={<GradebookYear search={search} year_id={year_id} accesses={accesses[5]} />} />}

                                {accesses[6].read && <Route path="proposition-year" element={<Propositions search={search} year_id={year_id} accesses={accesses[6]} />} />}

                                {accesses[7].read && <Route path="galerie" element={<AlbumYears search={search} year_id={year_id} accesses={accesses[7]} />} />}

                                {accesses[8].read && <Route path="announcement" element={<Announcements search={search} year_id={year_id} accesses={accesses[8]} />} />}

                                {accesses[9].read && <Route path="fees" element={<Fees search={search} year_id={year_id} accesses={accesses[9]} />} />}

                                {accesses[10].read && <Route path="paiements" element={<Payments search={search} year_id={year_id} accesses={accesses[10]} />} />}

                                {accesses[11].read && <Route path="etats" element={<Etats year_id={year_id} accesses={accesses[11]} />} />}

                                {accesses[12].read && <Route path="cycle-year" element={<CycleYear search={search} year_id={year_id} accesses={accesses[12]} />} />}

                                {accesses[13].read && <Route path="classe-year" element={<ClasseYear search={search} year_id={year_id} accesses={accesses[13]} />} />}

                                {accesses[14].read && <Route path="student-year" element={<StudentYear search={search} year_id={year_id} accesses={accesses[14]} />} />}

                                {accesses[15].read && <Route path="composition-year" element={<CompositionYear search={search} year_id={year_id} accesses={accesses[15]} />} />}

                                {accesses[16].read && <Route path="subject-year" element={<SubjectYear search={search} year_id={year_id} accesses={accesses[16]} />} />}

                                {accesses[17].read && <Route path="users" element={<Users search={search} accesses={accesses[17]} />} />}                                

                                {accesses[18].read && <Route path="rules" element={<Rules search={search}  accesses={accesses[18]} />} />}

                                {accesses[19].read && <Route path="cycles" element={<Cycles search={search} accesses={accesses[19]} />} />}

                                {accesses[20].read && <Route path="classes" element={<Classes search={search}  accesses={accesses[20]} />} />}

                                {accesses[21].read && <Route path="students" element={<Students search={search} accesses={accesses[21]} />} />}

                                {accesses[22].read && <Route path="compositions" element={<Compositions search={search} accesses={accesses[22]} />} />}

                                {accesses[23].read && <Route path="subjects" element={<Subjects search={search} accesses={accesses[23]} />} />}

                                {accesses[24].read && <Route path="albums" element={<Albums search={search} accesses={accesses[24]} />} />}

                                {accesses[25].read && <Route path="accounts" element={<Accounts search={search} accesses={accesses[25]} />} />}
                           
                                {accesses[26].read && <Route  path="logs" element={<Logs search={search} accesses={accesses[26]} />} />}

                                {accesses[27].read && <Route path="years" element={<Years search={search} accesses={accesses[27]}/>} />}

                                <Route path="profil" element={<Profil onChange={this.componentDidMount.bind(this)}/>} />
                            </Routes>  
                        }

                        {(type === 'student' || type === 'parent') &&                            
                            <Routes>  
                                <Route 
                                    path="dashboard"  
                                    element={<Dashboard 
                                        year_id={year_id} 
                                        accesses={permissions} 
                                    />} 
                                />

                                <Route 
                                    path="galerie" 
                                    element={<AlbumYears 
                                        search={search} 
                                        year_id={year_id} 
                                        accesses={permissions}
                                    />} 
                                />

                                <Route 
                                    path="fees" 
                                    element={<Fees 
                                        search={search} 
                                        year_id={year_id}
                                        accesses={permissions}
                                    />}
                                />

                                <Route 
                                    path="etats" 
                                    element={<Etats 
                                        year_id={year_id} 
                                        accesses={permissions}
                                    />}
                                />

                                <Route 
                                    path="students" 
                                    element={<Students 
                                        search={search} 
                                        accesses={permissions}
                                    />}
                                />

                                <Route 
                                    path="profil" 
                                    element={<Profil 
                                        onChange={this.componentDidMount.bind(this)}
                                    />}
                                />
                            </Routes>  
                        }
                    </Main>
                </Box>

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>
            </BrowserRouter >            
        )

    }

}