export function MainUrl() {
    return 'https://api-demo.gesco.school'
    // return 'http://127.0.0.1:8000'
}

export function SchoolName() {
    return 'GESTION SCOLAIRE'
}

export function FirstLine() {
    return "MINISTERE DE L'EDUCATION NATIONALE"
}

export function SecondLine() {
    return "DIRECTION NATIONALE DE"
}

export function ThirdLine() {
    return "L'ENSEIGNEMENT SECONDAIRE GENERAL"
}

export function FourthLine() {
    return "ACADEMIE D'ENSEIGNEMENT DE KATI"
}

export function FifthLine() {
    return "LYCEE PRIVE CHALLENGE ACADEMIE"
}

export function SixthLine() {
    return "Niamana - Face a la cite TELLEM"
}

export function SeventhLine() {
    return "Tél. : (223) 94 41 94 01 / 85 44 63 32"
}

export function EighthLine() {
    return "Email : hello.haidara@gmail.com"
}
