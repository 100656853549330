import React, { Component } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import axios from 'axios';
import AlertNotification from '../AlertNotification'
import BackdropLoader from '../BackdropLoader'
import {MainUrl, SchoolName} from '../../settings'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DoorSlidingSharpIcon from '@mui/icons-material/DoorSlidingSharp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LayersIcon from '@mui/icons-material/Layers';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export default class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cycles: [],
            classes: [],
            students: [],
            compositions: [],
            announcements: [],
            payments: [],

            name: "",
            description: "",
            debut: new Date(),
            fin: new Date(),
            show: false,

            message: "",
            severity: "",
            isAlert:false,
            loading: true                  
        }
    }

    componentDidMount(){
        document.title = SchoolName() + " | Dashboard"
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get(MainUrl() + '/api/v1/dashboard', config)
        .then(response => {
            this.setState({ 
                cycles: response.data.cycles,
                classes: response.data.classes,
                students: response.data.students,
                compositions: response.data.compositions,
                announcements: response.data.announcements,
                payments: response.data.payments,
                loading: false,
            });
        }).catch(error => {
            this.setState({
                loading: false,
                severity: 'error',
                message: error.response.data.error || "Une erreur inattendue s'est produite",
                isAlert: true
            }) 
        }); 
    }

    isAlertChange(){this.setState({isAlert:false}) }

    show(id){
        this.setState({loading: true}) 
        const item = this.state.announcements.filter(c => c["id"] === id)[0]
        this.setState({
            name: item.name,
            description: item.description,
            debut: new Date(item.debut),
            fin: new Date(item.fin),
            show: true,
            loading: false
        })
    }

    render() {

        const {
            cycles,
            classes,
            students,
            compositions,
            announcements,
            payments,

            name,
            description,
            debut,
            fin,
            show,

            loading,
            severity,
            message,
            isAlert,
        } = this.state

        let cycle = []
        let classe = []
        let student = []
        let composition = []
        let announcement = []
        let payment = []
        if(this.props.year_id !== null){
            cycle = cycles.filter(c => c["year_id"] === this.props.year_id)
            classe = classes.filter(c => c["year_id"] === this.props.year_id)
            student = students.filter(c => c["year_id"] === this.props.year_id)
            composition = compositions.filter(c => c["year_id"] === this.props.year_id)
            announcement = announcements.filter(c => c["year_id"] === this.props.year_id).slice(0, 5)
            payment = payments.filter(c => c["year_id"] === this.props.year_id)
        }

        const months = ["Janv", "Févr", "Mars", "Avri", "Maï", "Juin", "Juil", "Août", "Sept", "Oct", "Novem", "Dece"];

        let byMonthPayment = []
        payment.map((elem, index) => {
            elem.byMontth = months[new Date(elem.created_at).getMonth()] + ' ' + new Date(elem.created_at).getFullYear();
            byMonthPayment = byMonthPayment.concat(elem)
        })

        payment = byMonthPayment

        byMonthPayment = byMonthPayment.filter( (ele, ind) => ind === byMonthPayment.findIndex(elem => elem.byMontth === ele.byMontth))

        const valueFormatter = (value) => `${value.toLocaleString()}`;

        let dataClasse = [
            { dataKey: 'garçon', label: 'Garçon', valueFormatter },
            { dataKey: 'fille', label: 'Fille', valueFormatter },
        ]

        let xData = [{
            scaleType: 'band',
            categoryGapRatio: 0.73,
            barGapRatio: 0.1,
            data: []
        }]
        let  datasetx = []        
        classe.map((answer) => {
            const a = answer.classe.name
            xData[0].data = xData[0].data.concat(a)            
            let itemstudent = student.filter(c => c["classe_id"] === answer.classe.id)
            let garcon = itemstudent.filter(c => c["student"].sexe === 'M')
            let fille = itemstudent.filter(c => c["student"].sexe === 'F')
            const b = {
                garçon: garcon.length,
                fille: fille.length
            }
            datasetx = datasetx.concat(b)
        })

        let dataPayment = [
            { dataKey: 'paiements', label: 'Journal de caisse', valueFormatter },
        ]

        let datasetPayment = []
        let xDataPayment = [{
            scaleType: 'band',
            categoryGapRatio: 0.73,
            barGapRatio: 0.1,
            data: []
        }]

        byMonthPayment.map((answer) => {
            let azer = payment.filter(c => c["byMontth"] === answer.byMontth)
            let amont = 0
            azer.map((element) => {
                amont = amont + element.amont
            })
            const a =  months[new Date(answer.created_at).getMonth()] + ' ' + new Date(answer.created_at).getFullYear();;
            xDataPayment[0].data = xDataPayment[0].data.concat(a)
            const b =  {
                paiements: amont
            }
            datasetPayment = datasetPayment.concat(b)
        })
          
        let dataPercent = [
            { label: 'Garçon', value: ((student.filter(c => c["student"].sexe === 'M').length * 100)/(student.filter(c => c["student"].sexe === 'M').length + student.filter(c => c["student"].sexe === 'F').length)).toFixed(2) },
            { label: 'Fille', value: ((student.filter(c => c["student"].sexe === 'F').length * 100)/(student.filter(c => c["student"].sexe === 'M').length + student.filter(c => c["student"].sexe === 'F').length)).toFixed(2) },
        ]

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return (
            <Grid container spacing={3} mt={2}>
                {(this.props.accesses.type == 'student' || this.props.accesses.type == 'parent' || !this.props.accesses.read) ?
                    <Grid item xs={12}>
                        <Card elevation={3}  sx={{height:'100%', p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', flexDirection:'column', alignItems:'space-between'}}>
                            <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{textAlign:'center'}} ><Typography color="text.secondary" sx={{fontSize:16, fontWeight:800 }}>Annonces récentes</Typography></th>
                                    </tr>
                                </thead>

                                <tbody>  
                                    {announcement.map((answer) => (
                                        <tr>
                                            <td onClick={() => this.show(answer.id)}>
                                                <Button variant="text" style={{textTransform: 'none'}} onClick={() => this.show(answer.id)}>
                                                    <Typography color="text.secondary" sx={{fontSize:16 }}>{answer.name}</Typography>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Card>
                    </Grid>                
                :
                    <React.Fragment >
                        {/* First ligne */}
                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={3}  sx={{height:100, p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        color="inherit"
                                        sx={{fontWeight: 600, fontSize:36}}
                                    >
                                        {cycle.length > 10 ? cycle.length : "0" + cycle.length}
                                    </Typography>

                                    <Typography color="text.secondary" sx={{fontSize:18, fontWeight:800 }}>Cycles</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <ChangeCircleIcon sx={{fontSize:64, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={3}  sx={{height:100, p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        color="inherit"
                                        sx={{fontWeight: 600, fontSize:36}}
                                    >
                                        {classe.length > 10 ? classe.length : "0" + classe.length}
                                    </Typography>

                                    <Typography color="text.secondary" sx={{fontSize:18, fontWeight:800 }}>Classes</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <DoorSlidingSharpIcon sx={{fontSize:64, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={3}  sx={{height:100, p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        color="inherit"
                                        sx={{fontWeight: 600, fontSize:36}}
                                    >
                                        {student.length > 10 ? student.length : "0" + student.length}
                                    </Typography>

                                    <Typography color="text.secondary" sx={{fontSize:18, fontWeight:800 }}>Inscriptions</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <PeopleAltIcon sx={{fontSize:64, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={3}  sx={{height:100, p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        color="inherit"
                                        sx={{fontWeight: 600, fontSize:36}}
                                    >
                                        {composition.length > 10 ? composition.length : "0" + composition.length}
                                    </Typography>

                                    <Typography color="text.secondary" sx={{fontSize:18, fontWeight:800 }}>Compositions</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                                    <LayersIcon sx={{fontSize:64, color:"#306bff"}} /> 
                                </Box>
                            </Card>
                        </Grid>

                        {/* Second ligne */}
                        <Grid item xs={12} sm={12} md={12} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={9} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Card elevation={3}  sx={{height:'100%', p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                <BarChart
                                                    colors={['#306bff', '#e44b8d']}
                                                    series={dataClasse}
                                                    dataset={datasetx}
                                                    xAxis={xData}
                                                    height={300}      
                                                    // tooltip={{ trigger: 'item' }}                                      
                                                />
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} >
                                            <Card elevation={3}  sx={{height:'100%', p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                <BarChart
                                                    colors={['#306bff']}
                                                    series={dataPayment}
                                                    dataset={datasetPayment}
                                                    xAxis={xDataPayment}
                                                    height={300}      
                                                    tooltip={{ trigger: 'item' }}     
                                                    // barLabel="value"         
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3} >
                                    <Card elevation={3}  sx={{height:'100%', p:2, backgroundColor:'#ffffff', borderBottom:'1px solid #000000de', borderRadius:2, display: 'flex', flexDirection:'column', alignItems:'space-between'}}>
                                        <div style={{height: '50%', width:'100%'}}>
                                            <PieChart
                                                series={[
                                                    {
                                                        arcLabel: (item) => `${item.value}%`,
                                                        arcLabelMinAngle: 45,
                                                        data: dataPercent,                                    
                                                        innerRadius: 30,
                                                        outerRadius: 100,
                                                        paddingAngle: 5,
                                                        cornerRadius: 5,
                                                        startAngle: -180,
                                                        endAngle: 180,
                                                        cx: 100,
                                                        // cy: 150,
                                                    }                                
                                                ]}
                                                colors={['#306bff', '#e44b8d']}
                                                sx={{
                                                    [`& .${pieArcLabelClasses.root}`]: {
                                                    fill: 'white',
                                                    fontWeight: 'bold',
                                                    },
                                                }}
                                            />
                                        </div> 

                                        <div style={{height: '50%', width: '100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                                            <table className="table table-hover" style={{width:"100%", textAlign:'left'}}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{textAlign:'center'}} ><Typography color="text.secondary" sx={{fontSize:16, fontWeight:800 }}>Annonces récentes</Typography></th>
                                                    </tr>
                                                </thead>

                                                <tbody>  
                                                    {announcement.map((answer) => (
                                                        <tr>
                                                            <td>
                                                                <Button variant="text" style={{textTransform: 'none'}} onClick={() => this.show(answer.id)}>
                                                                    <Typography color="text.secondary" sx={{fontSize:16 }}>{answer.name}</Typography>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table> 
                                        </div>                                
                                    </Card>
                                </Grid>
                            </Grid>    
                        </Grid>                             
                    </React.Fragment>
                }
                  

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={show}
                    onClose={() => this.setState({show: false})}
                    scroll={'paper'}
                >
                    <DialogTitle id="scroll-dialog-title">{name}</DialogTitle> 

                    <DialogContent dividers={true}>
                        <DialogContentText tabIndex={-1} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>                                    
                                    {displaydate(debut) === displaydate(fin) ? 
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:800 }}>Date: {displaydate(debut)}</Typography>                                     
                                    :
                                        <Typography color="text.secondary" sx={{fontSize:16, fontWeight:800 }}>Du: {displaydate(debut)} au: {displaydate(fin)}</Typography>
                                    }

                                    <Typography color="text.secondary" sx={{fontSize:16}} mt={1}>{description}</Typography>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({show: false})}>Fermer</Button>
                    </DialogActions>
                </Dialog>                       

                <AlertNotification isAlert={isAlert} severity={severity} message={message}  onChange={this.isAlertChange.bind(this)}/>

                <BackdropLoader loading={loading} />
            </Grid>
        )

    }

}
